<template>
  <div ref="wrapper">
    <div class="d-flex flex-nowrap">
      <div style="flex-grow: 1; max-width: 100%">
        <div>
          <input v-model="value" type="hidden" />
        </div>
        <span v-if="field.hint" class="form-text text-muted">
          {{ field.hint }}
        </span>
        <span v-if="configValue" class="form-text text-muted pl-1">
          {{ configValueSyntax(configValue) }}
        </span>
        <span v-else-if="debugValue" class="form-text text-muted pl-1">
          {{ debugValue.syntax }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from "@/main";

export default {
  props: {
    field: {
      type: Object
    },
    configValues: {
      type: Array
    },
    outputValues: {
      type: Array,
      default: () => []
    },
    debugValues: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      value: "",
      configValue: null,
      debugValue: null
    };
  },
  computed: {
    isDisabled() {
      return (
        this.disabled ||
        (this.field.readonly !== undefined && this.field.readonly)
      );
    },
    inputType: function () {
      if (
        this.field.type === "int" &&
        this.value.toString().includes("{{", "}}")
      ) {
        return "text";
      }
      if (this.field.type === "string") {
        return "text";
      }
      if (this.showConfigValues) {
        return "text";
      }
      if (this.field.type === "typeCast") {
        return "text";
      }
      return "number";
    },
    configValuesFlat: function () {
      let configValues = [];
      this.configValues.forEach(configValue => {
        if (configValue.type === "json") {
          Object.entries(configValue.value).forEach(value => {
            configValues.push({
              label: `${configValue.label}.${value[0]}`,
              name: `${configValue.name}.${value[0]}`,
              value: value[1],
              type: "text"
            });
          });
        } else {
          configValues.push(configValue);
        }
      });
      return configValues;
    },
    configValuesFiltered: function () {
      if (
        this.field.configValuesDisabled !== undefined &&
        this.field.configValuesDisabled
      ) {
        return [];
      }

      let values =
        this.configValuesFlat.filter(item => this.checkType(item)) ?? [];
      if (!this.value || typeof this.value !== "string") return values;
      return values.filter(item => {
        return (
          JSON.stringify(item)
            .toLowerCase()
            .includes(this.value.toString().toLowerCase()) ||
          this.configValueSyntax(item).includes(this.value)
        );
      });
    },
    outputValuesFiltered: function () {
      if (
        this.field.outputValuesDisabled !== undefined &&
        this.field.outputValuesDisabled
      ) {
        return [];
      }
      if (!this.value || typeof this.value !== "string") {
        return this.outputValues;
      }
      return this.outputValues.filter(item => {
        return (
          item.value.toLowerCase().includes(this.value.toLowerCase()) ||
          this.outputValueSyntax(item).includes(this.value)
        );
      });
    },
    debugValuesFiltered: function () {
      if (!this.value || typeof this.value !== "string") {
        return this.debugValues;
      }

      return this.debugValues.filter(item => {
        if (typeof item.value !== "string") return true;

        return (
          item.value.toLowerCase().includes(this.value.toLowerCase()) ||
          item.syntax.includes(this.value)
        );
      });
    },
    debugValuesAllowed: function () {
      return (
        Object.keys(this.field).includes("debugValues") &&
        this.field.debugValues
      );
    },
    type: function () {
      if (this.field.type === "int") {
        return "number";
      } else if (
        this.field.type === "string" ||
        this.field.type === "textarea" ||
        this.field.type === "password"
      ) {
        return "text";
      }
      return "";
    }
  },
  watch: {
    value: function () {
      this.edited = true;
      let newValue = this.value;

      if (typeof newValue !== "boolean" && Number(newValue)) {
        newValue = parseFloat(newValue);
      } else if (newValue === "true" || newValue === "false") {
        newValue = Boolean(newValue);
      }

      if (this.configValue) {
        newValue = this.configValueSyntax(this.configValue);
      }

      this.$set(this.field, "value", newValue);
      this.$emit("change", newValue);
    }
  },
  mounted() {
    this.value = this.field.value ?? "";

    if (typeof this.value === "string" && this.value.startsWith("{{config")) {
      const configValue = this.configValuesFlat.find(
        cf => cf.name === this.value.replace("{{config.", "").replace("}}", "")
      );

      if (configValue) {
        this.setConfigValue(configValue);
      }
    } else if (
      typeof this.value === "string" &&
      this.value.startsWith("{{output")
    ) {
      const outputValue = this.outputValues.find(
        dv => dv.value === this.value.replace("{{output.", "").replace("}}", "")
      );
      if (outputValue) {
        this.setOutputValue(outputValue);
      }
    }
    bus.$on("fileAccessTreeSelected", this.onFileAccessTreeSelected);
  },
  destroyed() {
    bus.$off("fileAccessTreeSelected", this.onFileAccessTreeSelected);
  },
  methods: {
    configValueSyntax(configValue) {
      return `{{config.${configValue.name}}}`;
    },
    setConfigValue(configValue) {
      this.configValue = configValue;
      if (this.field.type === "json") {
        this.value[this.valueIndex.i][this.valueIndex.key] =
          this.configValueSyntax(configValue);
        return;
      }
      this.value =
        typeof configValue.value === "object"
          ? JSON.stringify(configValue.value)
          : configValue.value;
      this.debugValue = null;
    },
    outputValueSyntax(outputValue) {
      return `{{output.${outputValue.value}}}`;
    },
    setOutputValue(outputValue) {
      this.configValue = null;
      this.debugValue = null;
      this.$set(this.field, "jsonData", outputValue.data);
      if (this.field.type === "json") {
        this.value[this.valueIndex.i][this.valueIndex.key] =
          this.outputValueSyntax(outputValue);
        return;
      }
      this.value = this.outputValueSyntax(outputValue);
    },
    setDebugValue(debugValue) {
      this.debugValue = debugValue;
      this.$set(this.field, "jsonData", JSON.stringify(debugValue.value));
      if (typeof debugValue.value !== "string") {
        this.value = debugValue.syntax;
        return;
      }

      this.value = debugValue.value;
    },
    onFileAccessTreeSelected(selected) {
      if (this.field.name === "rootPath") {
        this.value = selected.rootPath;
      }
    }
  }
};
</script>
